<template>
  <div>
    <section id="login">
      <slot></slot>
    </section>
  </div>
</template>

<script>
// import VueSlickCarousel from "vue-slick-carousel";
// import "vue-slick-carousel/dist/vue-slick-carousel.css";
export default {
  name: "AuthLayout",
  components: {
    // VueSlickCarousel,
  },

  data() {
    return {
      settings: [{
        dots: false,
        arrows: false,
        edgeFriction: 0.35,
        fade: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
      },],
    };
  },
};
</script>
<style scoped>
/* button span {
  opacity: 0;
} */

.slick-slide img {
  display: initial !important;
}

@media all and (min-width: 768px) and (max-width: 991px) {
  .slider-main-box {
    display: none;
  }

  .main_row {
    margin: 20px 0px;
  }

  .login-lay-box {
    margin: 0 auto;
  }
}

@media all and (min-width: 320px) and (max-width: 767px) {
  .main_row {
    height: auto;
  }

  .slider-main-box {
    display: none;
  }

  .auth_data {
    padding: 10px 10px;
  }
}
</style>