<template>
  <div class="card-tm">
    <div class="card">
      <div class="card-body gradiant-bg">
        <div class="container">
          <div class="row justify-content-center align-items-center flex-column gap-3 py-3 flex-lg-row">
            <div class="col-12 col-lg-5 register login">
              <div class="card rounded-4 shadow p-3">
                <div class="card-body">
                  <div class="text-center">
                    <h6 class="fs-3 fw-600">Register</h6>
                    <p class="font-light-gray fs-14">
                      Trade with confidence on the safest and most reliable crypto exchange.
                    </p>
                  </div>
                  <div class=" flat-tabs">
                    <div class="tab-content" id="pills-tabContent">
                      <div class="tab-pane fade show active content-tab" id="pills-email" role="tabpanel"
                        aria-labelledby="pills-email-tab">
                        <div class="content-inner">
                          <form method="post" @submit.prevent="submitForm()">
                            <div class="phone-input">
                              <!-- <label class="form-label">Phone Number</label> -->
                              <vue-phone-number-input autocomplete="off"
                                v-model="vuePhone.phone" v-bind="vuePhone.props">
                              </vue-phone-number-input>
                              <!-- <div class="error" v-if="mobilesubmitted && !$v.form.mobile.required">
                                Phone Number is required
                              </div> -->
                            </div>
                            <div class="form-group" :class="{ 'form-group--error': $v.form.email.$error }">
                              <!-- <label class="form-label">Email Address</label> -->
                              <input type="email" class="form-control" placeholder="Enter your email"
                                id="exampleInputEmail1" aria-describedby="emailHelp" v-model.trim="$v.form.email.$model"
                                autocomplete="off" />
                              <!-- error msg  -->
                              <div class="error" v-if="submitted && !$v.form.email.required">
                                Email is required
                              </div>
                              <div class="error" v-if="submitted && !$v.form.email.email">
                                This must be an email
                              </div>
                            </div>
                            <div class="form-group" :class="{ 'form-group--error': $v.form.name.$error }">
                              <!-- <label for="referral" class="form-label">Referral Code (Optional)</label> -->
                              <input type="text" class="form-control" placeholder="Enter your name" id="name1"
                                v-model.trim="$v.form.name.$model" autocomplete="off" />
                              <!-- error msg  -->
                              <div class="error" v-if="submitted && !$v.form.name.required">
                                Name is required
                              </div>
                              <div class="error" v-if="submitted && !$v.form.name.minLength">
                                Name must be 3 character
                              </div>
                            </div>
                            <div class="form-group" :class="{ 'form-group--error': $v.form.password.$error }">
                              <!-- <label>Password</label> -->

                              <div class="input-group">
                                <input :type="hidden ? 'password' : 'text'" class="form-control m-0 "
                                  placeholder="Enter a password." autocomplete="off" id="CPassword1"
                                  v-model.trim="$v.form.password.$model" />
                                <span class="input-group-text" id="basic-addon1">
                                  <!-- hide-show icon  -->
                                  <div class="hide_show">
                                    <button type="button" class="btn p-0 shadow-none">
                                      <span @click="hidden = !hidden"
                                        v-html="hidden ? show_password : hide_password"></span>
                                    </button>
                                  </div>
                                </span>
                              </div>
                              <!-- error msg  -->
                              <div class="error" v-if="submitted && !$v.form.password.required">
                                Password is required
                              </div>
                              <div class="error" v-if="
                                submitted &&
                                !$v.form.password.valid &&
                                $v.form.password.required &&
                                $v.form.password.minLength
                              ">
                                Password should contains at least One Uppercase, One
                                Lowercase and One Special Character.
                              </div>
                              <div class="error" v-if="submitted && !$v.form.password.minLength">
                                Password must be 8 character
                              </div>
                            </div>

                            <div class="form-group" :class="{ 'form-group--error': $v.form.confirm_password.$error }">
                              <!-- <label>Password</label> -->

                              <div class="input-group">
                                <input :type="'text'" class="form-control m-0" placeholder="Enter a confirm password"
                                  autocomplete="off" id="exampleInputPassword1"
                                  v-model.trim="$v.form.confirm_password.$model" />
                                <span class="input-group-text" id="basic-addon1">
                                  <!-- hide-show icon  -->
                                  <!-- <div class="hide_show">
                              <button type="button" class="btn p-0 shadow-none">
                                <span @click="hidden = !hidden" v-html="hidden ? show_password : hide_password"></span>
                              </button>
                            </div> -->
                                </span>
                              </div>
                              <!-- error msg  -->
                              <div class="error" v-if="submitted && !$v.form.confirm_password.required">
                                Confirm Password is required
                              </div>
                              <div class="error" v-else-if="
                                submitted &&
                                $v.form.confirm_password.$error
                              ">
                                Confirm password must be same as password
                              </div>
                            </div>

                            <div class="form-group" :class="{ 'form-group--error': $v.form.referral.$error }">
                              <!-- <label for="referral" class="form-label">Referral Code (Optional)</label> -->
                              <input type="text" class="form-control" placeholder="Enter a referral code (optional)"
                                id="referral" v-model.trim="$v.form.referral.$model" autocomplete="off" />
                              <!-- error msg  -->
                              <div class="error" v-if="submitted && !$v.form.referral.minLength">
                                Referral Code must be 10 character
                              </div>
                              <div class="error" v-if="submitted && !$v.form.referral.maxLength">
                                Referral Code cant be more than 10 character
                              </div>
                            </div>
                            <div class="col-md-12 mb-3">
                              <div class="d-flex gap-1">
                                <div>
                                  <input class="shadow-none" type="checkbox" v-model.trim="$v.form.terms.$model" />
                                </div>
                                <div>
                                  <router-link target='_blank' to="/About/terms_and_condition"
                                    class="form-check-label fw-normal">
                                    I agree to terms & conditions.
                                  </router-link>
                                </div>
                              </div>
                              <div class="error" v-if="submitted && !$v.form.terms.required">
                                Terms and conditions are required.
                              </div>

                            </div>

                            <div class="col-12 mb-3">
                              <!-- loader  -->
                              <button v-if="loading" type="button" class="btn-action w-100">
                                <div class="spinner-border spinner-border-sm" role="status">
                                  <span class="visually-hidden">Loading...</span>
                                </div>
                              </button>
                              <!-- sign-btn  -->
                              <button v-else type="submit" class="btn-action w-100">
                                <div class="d-flex justify-content-between">
                                  <span>Register</span>
                                  <span><i class="fas fa-long-arrow-right"></i></span>
                                </div>
                              </button>
                            </div>
                            <div class="bottom">
                              <p>Already have an account?</p>
                              <router-link to="login">Login</router-link>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div class="tab-pane fade content-tab" id="pills-mobile" role="tabpanel"
                        aria-labelledby="pills-mobile-tab">
                        <div class="content-inner">
                          <form method="post" @submit.prevent="submitMobileForm()">

                            <div class="phone-input" :class="{ 'form-group--error': $v.formmobile.mobile.$error }">
                              <!-- <label class="form-label">Phone Number</label> -->
                              <vue-phone-number-input v-model.trim="$v.formmobile.mobile.$model" autocomplete="off"
                                v-model="vuePhone.phone" v-bind="vuePhone.props" @update="mobileNumberPayload">
                              </vue-phone-number-input>
                              <div class="error" v-if="mobilesubmitted && !$v.formmobile.mobile.required">
                                Phone Number is required
                              </div>
                            </div>
                            <div class="form-group" :class="{ 'form-group--error': $v.formmobile.name.$error }">
                              <!-- <label for="referral" class="form-label">Referral Code (Optional)</label> -->
                              <input type="text" class="form-control" placeholder="Enter your name" id="name"
                                v-model.trim="$v.formmobile.name.$model" autocomplete="off" />
                              <!-- error msg  -->
                              <div class="error" v-if="mobilesubmitted && !$v.formmobile.name.required">
                                Name is required
                              </div>
                              <div class="error" v-if="mobilesubmitted && !$v.formmobile.name.minLength">
                                Name must be 3 character
                              </div>
                            </div>
                            <div class="form-group" :class="{ 'form-group--error': $v.formmobile.password.$error }">
                              <!-- <label>Password</label> -->

                              <div class="input-group">
                                <input :type="hidden ? 'password' : 'text'" class="form-control m-0"
                                  placeholder="Enter a password" autocomplete="off" id="exampleInputPassword2"
                                  v-model.trim="$v.formmobile.password.$model" />
                                <span class="input-group-text" id="basic-addon1">
                                  <!-- hide-show icon  -->
                                  <div class="hide_show">
                                    <button type="button" class="btn p-0 shadow-none">
                                      <span @click="hidden = !hidden"
                                        v-html="hidden ? show_password : hide_password"></span>
                                    </button>
                                  </div>
                                </span>
                              </div>
                              <!-- error msg  -->
                              <div class="error" v-if="mobilesubmitted && !$v.formmobile.password.required">
                                Password is required
                              </div>
                              <div class="error" v-if="
                                mobilesubmitted &&
                                !$v.formmobile.password.valid &&
                                $v.formmobile.password.required &&
                                $v.formmobile.password.minLength
                              ">
                                Password should contains at least One Uppercase, One
                                Lowercase and One Special Character.
                              </div>
                              <div class="error" v-if="mobilesubmitted && !$v.formmobile.password.minLength">
                                Password must be 8 character
                              </div>
                            </div>
                            <div class="form-group"
                              :class="{ 'form-group--error': $v.formmobile.confirm_password.$error }">
                              <!-- <label>Password</label> -->

                              <div class="input-group">
                                <input :type="'text'" class="form-control m-0" placeholder="Enter a confirm password"
                                  autocomplete="off" id="Cpwd2"
                                  v-model.trim="$v.formmobile.confirm_password.$model" />
                                <span class="input-group-text" id="basic-addon1">
                                  <!-- hide-show icon  -->
                                  <!-- <div class="hide_show">
                              <button type="button" class="btn p-0 shadow-none">
                                <span @click="hidden = !hidden" v-html="hidden ? show_password : hide_password"></span>
                              </button>
                            </div> -->
                                </span>
                              </div>
                              <!-- error msg  -->
                              <div class="error" v-if="mobilesubmitted && !$v.formmobile.confirm_password.required">
                                Confirm Password is required
                              </div>
                              <div class="error" v-else-if="
                                mobilesubmitted &&
                                $v.formmobile.confirm_password.$error
                              ">
                                Confirm password must be same as password
                              </div>
                            </div>
                            <div class="form-group" :class="{ 'form-group--error': $v.formmobile.referral.$error }">
                              <!-- <label for="referral" class="form-label">Referral Code (Optional)</label> -->
                              <input type="text" class="form-control" placeholder="Enter a referral code (optional)"
                                id="referral1" v-model.trim="$v.formmobile.referral.$model" autocomplete="off" />
                              <!-- error msg  -->
                              <div class="error" v-if="mobilesubmitted && !$v.formmobile.referral.minLength">
                                Referral Code must be 10 character
                              </div>
                              <div class="error" v-if="mobilesubmitted && !$v.formmobile.referral.maxLength">
                                Referral Code cant be more than 10 character
                              </div>
                            </div>
                            <div class="col-md-12 mb-3">
                              <div class="d-flex gap-1">
                                <div>
                                  <input class="shadow-none" type="checkbox"
                                    v-model.trim="$v.formmobile.terms.$model" />
                                </div>
                                <div>
                                  <router-link target='_blank' to="/About/terms_and_condition"
                                    class="form-check-label fw-normal">
                                    I agree to terms & conditions.
                                  </router-link>
                                </div>
                              </div>
                              <!-- <div
                      class="error"
                      v-if="submitted && !$v.form.terms.required"
                    >
                      Terms and conditions are required.
                    </div> -->
                              <div class="error" v-if="mobilesubmitted && !$v.formmobile.terms.sameAs">
                                Terms and conditions are required.
                              </div>
                            </div>

                            <div class="col-12 mb-3">
                              <!-- loader  -->
                              <button v-if="loading" type="button" class="btn-action w-100">
                                <div class="spinner-border spinner-border-sm" role="status">
                                  <span class="visually-hidden">Loading...</span>
                                </div>
                              </button>
                              <!-- sign-btn  -->
                              <button v-else type="submit" class="btn-action w-100">
                                <div class="d-flex justify-content-between">
                                  <span>Register</span>
                                  <span><i class="fas fa-long-arrow-right"></i></span>
                                </div>
                              </button>
                            </div>
                            <div class="bottom">
                              <p>Already have an account?</p>
                              <router-link to="login">Login</router-link>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="col-12 col-lg-6">
              <img :src="require(`../../../public/assets/images/layout/auth-bg.png`)" class="img-fluid" />
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
// import StepsComponent from "@/components/ui/StepsComponent.vue";
Vue.component('vue-phone-number-input', VuePhoneNumberInput);
import ApiClass from "../../api/api";
import {
  required,
  email,
  maxLength,
  minLength,
  sameAs,
} from "vuelidate/lib/validators";
export default {
  name: "SignUp",
  components: {
    // StepsComponent
  },
  data() {
    return {
      steps: [
        { title: 'Competitive Fees', subtitle: 'Reasonable transaction fees to maximize profit.' },
        { title: 'Wide Range of Cryptocurrency', subtitle: 'A diverse selection of cryptocurrency available for trading' },
        { title: 'Advance Trading Tools', subtitle: 'Features such as Advance Trading Tools, Analysis Indicator, Sentiment Analysis stc. ' },
        { title: 'High Security Measures', subtitle: 'Adv. security protocols, including two factor authentication' },
      ],
      loading: false,
      hidden: true,
      hidden1: true,
      show: false,
      show_password: '<i class="far fs-5 mt-2 fa-eye"></i>',
      hide_password: '<i class="far fs-5 mt-2 fa-eye-slash"></i>',
      submitted: false,
      mobilesubmitted: false,
      form: {
        email: "",
        password: "",
        referral: "",
        terms: "",
        confirm_password: "",
        name: "",
      },
      formmobile: {
        mobile: "",
        country_calling_code: "",
        country_code: "",
        password: "",
        referral: "",
        terms: "",
        confirm_password: "",
        name: ""
      },
      vuePhone: {
        phone: "",
        props: {
          clearable: true,
          fetchCountry: true,
          preferredCountries: ["US", "GB"],
          noExample: false,
          translations: {
            countrySelectorLabel: "Country code",
            countrySelectorError: "Error",
            phoneNumberLabel: "Enter your phone",
            example: "Example:",
          },
        },
      }
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      name: {
        required,
        minLength: minLength(3)
      },
      confirm_password: { required, sameAsPassword: sameAs("password") },
      password: {
        required,
        valid: function (value) {
          const containsUppercase = /[A-Z]/.test(value);
          const containsLowercase = /[a-z]/.test(value);
          const containsSpecial = /[#?!@$%^&*-]/.test(value);
          return containsUppercase && containsLowercase && containsSpecial;
        },
        minLength: minLength(8),
      },
      referral: {
        minLength: minLength(6),
        maxLength: maxLength(12),
      },
      terms: {
        sameAs: sameAs(() => true),
      },
      // mobile: {
      //   required,
      //   minLength: minLength(10)
      // },
    },
    formmobile: {

      mobile: {
        required,
        minLength: minLength(10)
      },
      name: {
        required,
        minLength: minLength(3)
      },
      confirm_password: { required, sameAsPassword: sameAs("password") },
      password: {
        required,
        valid: function (value) {
          const containsUppercase = /[A-Z]/.test(value);
          const containsLowercase = /[a-z]/.test(value);
          const containsSpecial = /[#?!@$%^&*-]/.test(value);
          return containsUppercase && containsLowercase && containsSpecial;
        },
        minLength: minLength(8),
      },
      referral: {
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
      terms: {
        sameAs: sameAs(() => true),
      },
    },
  },
  methods: {
    resetform() {
      this.form = {
        name: "",
        email: "",
        confirm_password: "",
        password: "",
        referral: "",
        terms: "",
        mobile: "",
      };
      this.formmobile = {
        mobile: "",
        confirm_password: "",
        name: "",
        password: "",
        referral: "",
        terms: ""
      }
    },
    async submitMobileForm() {
      this.mobilesubmitted = true;
      if (this.$v.formmobile.$invalid) {
        return;
      }
      // console.log("testing portion", this.form);
      this.mobilesubmitted = false;
      this.loading = true;
      var result = await ApiClass.postRequest("registerMobile", false, this.formmobile);
      if (result.data.status_code == 1) {
        this.loading = false;

        this.$swal({
          toast: true,
          position: "top-end",
          icon: "success",
          title: result.data.message,
          showConfirmButton: false,
          timer: 3000,
        });

        return this.$router.push({
          name: "verifyMobile",
          params: {
            data: result.data.data,
          },
        });
        // this.resetform();
      } else {
        this.loading = false;
        this.$swal({
          toast: true,
          position: "top-end",
          icon: "error",
          title: result.data.message,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },
    async submitForm() {
      this.submitted = true;
      if (this.$v.form.$invalid) {
        return;
      }
      // console.log("testing portion", this.form);
      this.submitted = false;
      this.loading = true;
      var result = await ApiClass.postRequest("register", false, this.form);
      if (result.data.status_code == 1) {
        this.loading = false;
        this.$swal({
          toast: true,
          position: "top-end",
          icon: "success",
          title: result.data.message,
          showConfirmButton: false,
          timer: 3000,
        });
        this.resetform();
      } else {
        this.loading = false;
        this.$swal({
          toast: true,
          position: "top-end",
          icon: "error",
          title: result.data.message,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },
    mobileNumberPayload(payload) {
      //console.log(payload);

      if (payload && payload.countryCode) {
        this.formmobile.country_code = payload.countryCode
      }
      if (payload && payload.countryCallingCode) {
        this.formmobile.country_calling_code = payload.countryCallingCode
      }
      if (payload && payload.nationalNumber) {
        this.formmobile.mobile = payload.nationalNumber
      }
    }
  },
  created() {

    this.form.referral = this.$route.query.referral
      ? this.$route.query.referral
      : "";
    this.formmobile.referral = this.$route.query.referral
      ? this.$route.query.referral
      : "";
  },
  mounted() {
    if (this.$route.params.email != null) {
      // console.log(this.$route.params.email);
      this.form.email = this.$route.params.email;
    }
  },

};
</script>

