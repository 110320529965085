<template>

    <AuthLayout>
        <div class="card-tm">
            <div class="card">
                <div class="card-body gradiant-bg py-lg-5">
                    <div class="container" id="verification" v-if="otpValue">
                        <div class="row justify-content-center align-items-center flex-column gap-3 py-3 flex-lg-row">
                            <div class="col-12 col-lg-5 register login">
                                <div class="card shadow rounded-4 text-center p-3">
                                    <div class="card-body">
                                        <div>
                                            <h6 class="fs-3 fw-600">Verify</h6>
                                            <p class="font-light-gray fs-14">
                                                The most reliable crypto exchange
                                            </p>
                                        </div>
                                        <div class=" flat-tabs text-start">
                                            <div class="content-inner">
                                                <div class="content-tab">
                                                    <div class="content-inner">
                                                        <form @submit.prevent="validateOtp()">
                                                            <div class="d-flex justify-content-between">
                                                                <label for="exampleInputtext"
                                                                    class="form-label m-0 fs-14 fw-semibold">
                                                                    {{
                                                                        otpValue.otp_type == "withdraw" ?
                                                                            this.capitalizeFirstLetter(otpValue.otp_type) : ''
                                                                    }}
                                                                    {{
                                                                        this.capitalizeFirstLetter(otpValue.send_type)
                                                                    }} Verification Code
                                                                </label>
                                                                <span id="timer-color" class="badge"
                                                                    :style="{ color: timerClr }">{{
                                                                        timeLeft
                                                                    }}</span>
                                                                <button type="button" v-if="!disabled"
                                                                    class="btn p-0 text-primary fw-bolder fs-14"
                                                                    @click.prevent="resendOtp(otpValue)">
                                                                    Resend Code
                                                                </button>
                                                            </div>
                                                            <div class="d-md-flex align-items-center justify-content-between"
                                                                :class="{ 'form-group--error': $v.form.otp.$error }">
                                                                <input type="number" placeholder=""
                                                                    class="form-control py-2" id="exampleInputtext"
                                                                    aria-describedby="emailHelp"
                                                                    v-model.trim="$v.form.otp.$model"
                                                                    onkeypress="return (event.charCode !=8 && ((event.charCode >= 48 && event.charCode <= 57) || (event.charCode == 46 && this.value.indexOf('.') == 1)))"
                                                                    autocomplete="off" />

                                                            </div>
                                                            <small class="fs-12 fw-semibold">Enter the 6-digit code sent
                                                                to
                                                                <strong>{{
                                                                    this.capitalizeFirstLetter(otpValue.send_type)
                                                                }}</strong></small>
                                                            <div class="error"
                                                                v-if="submitted && !$v.form.otp.required">
                                                                {{ otp_text }} is required
                                                            </div>
                                                            <div class="error"
                                                                v-if="submitted && !$v.form.otp.maxLength">
                                                                {{ otp_text }} must be less than 6 characters
                                                            </div>
                                                            <div class="verify-buttons mt-3">
                                                                <button v-if="loading" type="button"
                                                                    class="btn btn-primary shadow-none w-100">
                                                                    <div class="spinner-border" role="status">
                                                                        <span class="visually-hidden">Loading...</span>
                                                                    </div>
                                                                </button>

                                                                <button v-else type="submit" class="btn-action">
                                                                    <div class="d-flex justify-content-between">
                                                                        <span>Verify</span>
                                                                        <span><i
                                                                                class="fas fa-long-arrow-right"></i></span>
                                                                    </div>
                                                                </button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-12 col-lg-6">
                                <img :src="require(`../../../public/assets/images/layout/auth-bg.png`)"
                                    class="img-fluid" />
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </AuthLayout>
</template>

<script>
import AuthLayout from "@/layouts/AuthLayout.vue";
import ApiClass from "../../api/api";
import {
    required,
    maxLength
} from "vuelidate/lib/validators";

export default {
    name: "verify",
    components: {
        AuthLayout,
    },
    data() {
        return {
            steps: [
                { title: 'User Friendly Interface', subtitle: 'Simple interface taht make it easy for user trade' },
                { title: 'High Security Measures', subtitle: 'Adv. security protocols, including two factor authentication' },
            ],
            type: "password",
            name: "Login",
            timerClr: "green",
            submitted: false,
            timeLeft: "",
            otpValue: "",
            resendLoading: false,
            loading: false,
            x: "",
            otp_text: "OTP",
            disabled: true,
            form: {
                otp: "",
            },
            is_2fa: "",
            is_2fa_verify: false,
        };
    },
    validations: {
        form: {
            otp: {
                required,
                maxLength: maxLength(6),
            },
        },
    },
    mounted() {
        if (!this.$route.params.data) {
            return this.$router.push("/login");
        }
        console.log(this.$route.params.data);
        this.otpValue = this.$route.params.data;
        // otp_type: "withdraw"
        this.timer(this.otpValue.expired_at);
    },
    /*   watch: {

      timeLeft: function(){
         console.log(this.x);
        /* if(this.x < 120){
          //this.timerClr = 'red';
        } 
      }
    }, */
    methods: {
        async validateOtp() {
            this.submitted = true;
            if (this.$v.form.$invalid) {
                return;
            }
            this.submitted = false;
            this.loading = true;
            let url = this.otpValue.otp_type == "withdraw" ? "block/validateotpWith" : (this.is_2fa == 2 ? 'http://localhost:3000/user/verify2FA' : 'validateotp');
            let bool = this.otpValue.otp_type == "withdraw" ? true : false;
            var result = await ApiClass.postRequest(url, bool, {
                email: this.otpValue.email,
                otp: this.form.otp,
            });

            if (result.data.status_code == 1) {
                this.loading = false;
                if (this.x) {
                    this.form.otp = "";
                    clearInterval(this.x);
                    this.timeLeft = "";
                }
                this.$swal({
                    toast: true,
                    position: "top-end",
                    icon: "success",
                    title: result.data.message,
                    showConfirmButton: false,
                    timer: 3000,
                });

                await new Promise((resolve) => setTimeout(resolve, 1000));

                if (this.otpValue.otp_type == "withdraw") {
                    return this.$router.push("/wallet/spot");
                }

                localStorage.setItem("user", JSON.stringify(result.data.data.user));
                await new Promise((resolve) => setTimeout(resolve, 1000));

                // await this.get_setting();

                // if(this.is_2fa == 2 && !this.is_2fa_verify){
                //     this.is_2fa_verify = true;
                //     this.otp_text = "TOTP";
                //     this.otpValue.send_type = "Google Authenticator"
                //    return this.$router.push({ path: "/verify",query:{'type2FA':'google'}});
                // }

                localStorage.setItem("token", result.data.data.token);
                return this.$router.push("/spot/BTCUSDT");

            } else {
                this.loading = false;
                this.$swal({
                    toast: true,
                    position: "top-end",
                    icon: "error",
                    title: result.data.message,
                    showConfirmButton: false,
                    timer: 3000,
                });
                if (this.otpValue.otp_type == "withdraw") {
                    //return this.$router.push("/spot/BTCUSDT");
                }
            }
        },
        async validateTwoStep() {
            this.resendLoading = false;
            this.disabled = true;
        },
        async resendOtp(userData) {
            this.resendLoading = true;
            this.disabled = true;
            this.timerClr = "green";
            var param = {
                type: userData.otp_type,
                email: userData.email
            };


            let url = this.otpValue.otp_type == "withdraw" ? "block/resendOtpWith" : "resendotp";
            let bool = this.otpValue.otp_type == "withdraw" ? true : false;

            var result = await ApiClass.postRequest(url, bool, param);
            if (result.data.status_code == 1) {
                this.resendLoading = false;
                this.otpValue = result.data.data;
                this.timer(this.otpValue.expired_at); // count down timer for enter the otp...........
                this.$swal({
                    toast: true,
                    position: "top-end",
                    icon: "success",
                    title: result.data.message,
                    showConfirmButton: false,
                    timer: 3000,
                });
            }
        },
        timer(countBackTime) {
            this.disabled = true;
            var countDownDate = new Date(countBackTime).getTime();

            if (this.x) {
                // clear the interval if user again click on the sign in button.............
                clearInterval(this.x);
                this.timeLeft = "";
            }

            this.x = setInterval(() => {
                var now = new Date().getTime();
                var distance = countDownDate - now;
                var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((distance % (1000 * 60)) / 1000);

                this.timeLeft =
                    "Resend Code in " + ((minutes) ? minutes + "m" + "-" : "") + seconds + " seconds ";

                if (minutes * 60 < 100) {
                    this.timerClr = "var(--bs-danger)";
                }
                //console.log(distance)

                if (distance < 0) {
                    // clear the interval if the otp is expired..........
                    clearInterval(this.x);
                    this.disabled = false;
                    this.timeLeft = '';
                }
            }, 1000);
        },
    },
};
</script>
<style>
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}
</style>